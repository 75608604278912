import {Injectable} from '@angular/core';
import EventNames = Gtag.EventNames;
import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;
import * as loggRocket from "logrocket";

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    trackEvent(eventType: EventNames | (string & {}), attributes?: ControlParams | EventParams | CustomParams) {
        try {
            gtag('event', eventType, attributes)
            loggRocket.track(eventType)
        } catch {
        }
    }
}
